import { notification } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { toJson } from 'shared/json'

export const fetcher = axios.create({
  baseURL: `${process.env.REACT_APP_API_NEW_BASE_URL}/bp-internal`,
})

export default function setupNewAxios() {
  fetcher.interceptors.request.use(async (request) => {
    try {
      const firebaseAuth = getAuth()
      const token = await getIdToken(firebaseAuth.currentUser)
      request.headers.authorization = `Bearer ${token}`
    } catch (e) {
      console.error('axios:getIdToken', e)
    }

    if (!(request.data instanceof FormData)) {
      request.data = decamelizeKeys(request.data)
    }
    request.params = decamelizeKeys(request.params)

    return request
  })

  fetcher.interceptors.response.use(
    (res) => {
      if (res.config.responseType === 'blob') {
        return res.data
      }
      return camelizeKeys(res.data)
    },
    (err) => {
      console.error('axios:response.error', { err })
      notification.error({
        message: err.message,
        description: getResponseErrorMessage(err),
        duration: null,
      })
      return Promise.reject(err.response?.data)
    }
  )
}

function getResponseErrorMessage(err) {
  return (
    toJson(err.response?.data?.error?.detail?.fields) ||
    err.response?.data?.error?.msg ||
    defaultHttpErrorMessage(err)
  )
}

function defaultHttpErrorMessage(err) {
  return err.response ? toJson(err.response.data) : 'Something went wrong.'
}
