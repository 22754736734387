import { notification } from 'antd'
import axios from 'axios'
import { getAuth, getIdToken } from 'firebase/auth'
import i18n from 'i18next'
import log from 'loglevel'
import { toJson } from 'shared/json'

export default function setupAxios() {
  axios.defaults.baseURL = process.env.REACT_APP_API_LB_URL
  axios.interceptors.request.use(async (request) => {
    // check firebase auth token
    const firebaseAuth = getAuth()
    try {
      // https://firebase.google.com/docs/reference/js/auth.md?authuser=0#getidtoken
      // Returns the current token if it has not expired or if it will not
      // expire in the next five minutes. Otherwise, this will refresh the
      // token and return a new one.
      const token = await getIdToken(firebaseAuth.currentUser)
      if (!request.url.startsWith('https://api.github.com')) {
        request.headers.authorization = `JWT ${token}`
      }
    } catch (e) {
      log.error('axios:getIdToken', e)
    }
    request.baseURL = process.env.REACT_APP_API_LB_URL
    return request
  })

  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response.data)
    },
    (error) => {
      log.debug('axios:response.error', { error })
      const info = error.response?.data?.info ?? {}
      notification.error({
        message: info.code || error.message,
        description: getResponseErrorMessage(error),
        duration: null,
      })
      return Promise.reject(error.response?.data)
    }
  )
}

function getResponseErrorMessage(error) {
  const code = error.response?.data?.info?.code
  const data = error.response?.data?.data
  switch (code) {
    case 3090008:
      return i18n.t(
        '此帳戶已設定於 {{companyName}}－{{organizationName}} 故無法重複設定，請重新確認',
        {
          companyName: data?.companyName,
          organizationName: data?.organizationName,
        }
      )
    case 3090009:
      return i18n.t('發票號碼或invoice號碼錯誤，請重新確認')
    default:
      return error.response?.data?.info?.msg || defaultHttpErrorMessage(error)
  }
}

function defaultHttpErrorMessage(error) {
  return error.response ? toJson(error.response.data) : ''
}
