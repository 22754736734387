import log, { levels } from 'loglevel';

// TRACE: 0;
// DEBUG: 1;
// INFO: 2;
// WARN: 3;
// ERROR: 4;
// SILENT: 5;
export default function setupLog() {
  if (process.env.NODE_ENV === 'development') {
    log.setLevel(levels.TRACE, false);
    return;
  }
  const level = localStorage.getItem(
    `${process.env.REACT_APP_STORAGE_PREFIX}:logLevel`
  );
  log.setLevel(level != null ? +level : levels.INFO, false);
}
