import { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import setupAxios from 'shared/setup/setupAxios'
import setupFirebase from 'shared/setup/setupFirebase'
import setupI18n from 'shared/setup/setupI18n'
import setupLog from 'shared/setup/setupLog'
import setupNewAxios from 'shared/setup/setupNewAxios'
import 'antd/dist/antd.variable.min.css'
import '@ant-design/flowchart/dist/index.css'

const App = lazy(() => import('./App'))

function prepare() {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = require('mocks/browser')
    return worker.start()
  }

  return Promise.resolve()
}

prepare().then(async () => {
  setupLog()
  setupFirebase()
  setupAxios()
  setupNewAxios()
  await setupI18n()

  ReactDOM.render(
    <Suspense fallback={null}>
      <App />
    </Suspense>,
    document.getElementById('root')
  )
})
